import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurServices from "./pages/OurServices";
import VisionMision from "./pages/VisionMision";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/vision-mision" element={<VisionMision />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
