import React from "react";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div className="flex items-center">
      <div className="w-full py-11 md:py-0 md:w-1/2 px-11 md:pl-[72px] flex flex-col items-center md:items-baseline md:flex-none">
        <p className="text-3xl text-center md:text-left md:text-5xl font-bold mb-2">
          Empowering Industries
        </p>
        <p className="text-3xl text-center md:text-left md:text-5xl font-bold">
          Ensuring Water Security
        </p>
        <p className="mt-4 mb-11 text-center md:text-left ">
          A Leading Technology Company Specializing in Industrial Digitization
          and Environmental Monitoring
        </p>

        <Link to={"/about-us"}>
          <button className="bg-[#1172B8] px-4 py-2 rounded-md text-white">
            Tentang Kami
          </button>
        </Link>
      </div>
      <div className="w-1/2 hidden md:block">
        <img src="/assets/hero-pict.png" alt="" />
      </div>
    </div>
  );
}

export default Hero;
