import React from "react";

function AboutUs() {
  return (
    <div>
      <div className="flex justify-center py-11 md:py-0 h-screen items-center">
        <div className="w-1/2 hidden md:block">
          <img src="/assets/about-us-pict.png" alt="" width={700} />
        </div>
        <div className="w-full md:w-1/2 flex flex-col justify-center px-11 md:px-0 md:pr-16 text-center md:text-left">
          <p className="text-[#1172B8] text-4xl font-bold mb-11">
            Tentang Kami
          </p>
          <p className="mb-4">
            <span className="text-[#1172B8] font-bold">
              Buana Biru Teknindo
            </span>{" "}
            adalah leading technology company yang memiliki spesialisasi pada
            digitalisasi industri dan pemantauan lingkungan.
          </p>
          <p className="mb-4">
            Kantor pusat di Kota Bogor dengan fasilitas Workshop berada di Kota
            Depok, didukung oleh team ahli dari berbagai disiplin ilmu dengan
            pengalaman lebih dari 10 tahun dibidang Instrumentation dan
            Environmental.
          </p>
          <p>
            {" "}
            Kami berkomitmen untuk memberikan monitoring system secara
            real-time, online, dan cost-effective dengan teknologi wireless yang
            kami kembangkan.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
