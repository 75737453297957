import React from "react";
import CardServices from "../components/CardServices";

function OurServices() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-11 px-11 my-11">
      <CardServices
        image="/assets/layanan-1.png"
        title="Mesh Network Connectivity"
        description="Dengan penggunaan jaringan wireless untuk area terbatas, pemantauan secara realtime dan online dapat dilakukan tanpa penggunaan internet di setiap titik pemantauan."
      />
      <CardServices
        image="/assets/layanan-2.png"
        title="Water Management"
        description="Penggunaan sensor kualias dan kuantitas air digabungkan dengan SpiderWave memungkinan pemantauan secara Realtime dan Online."
      />
      <CardServices
        image="/assets/layanan-3.png"
        title="Effluent Monitoring"
        description="Berdasarkan kepada PERMENLHK No.80 - 2019 tentang pemantauan kualitas air limbah secara terus menerus dan dalam jaringan untuk kegiatan Tambang Batubara."
      />
      <CardServices
        image="/assets/layanan-4.png"
        title="Pollution Monitoring"
        description="Pemantauan Occupational Health & Industrial Hygiene di area tambang maupun area kerja non-tambang."
      />
      <CardServices
        image="/assets/layanan-5.png"
        title="Smart PJU System"
        description="Penghematan Energi, melalui fitur dimming otomatis, energi dapat dihemat sesuai kebutuhan.
Penurunan Biaya Pemeliharaan, Petugas tidak melakukan pemeriksaan satu per satu. Pemeliharaan dilakukan jika terjadi kerusakan.
Konsumsi listrik menggunakan solar sehingga tidak ada OPEX untuk biaya listrik."
      />
      <CardServices
        image="/assets/layanan-6.png"
        title="Intelligence Video Analytics"
        description="Penggunaan Software berbasis Ai untuk menganalisa video dari CCTV. "
      />
    </div>
  );
}

export default OurServices;
