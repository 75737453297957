import React from "react";

function VisionMision() {
  return (
    <div className="flex flex-col items-center justify-center my-6 gap-11 px-11 md:px-0">
      <div className="w-full md:w-1/3 p-4 bg-[#1172B8] text-white shadow-card rounded-md">
        <p className="text-center text-xl font-bold mb-4">Visi Perusahaan</p>
        <p>
          Untuk menciptakan lingkungan yang sustainable dan saling terkoneksi
          dengan memanfaatkan teknologi wireless yang inovatif untuk sistem
          pemantauan online dalam aspek lingkungan hidup dan digitalisasi
          industri.
        </p>
      </div>
      <div className="w-full md:w-1/3 p-4 bg-[#1172B8] text-white shadow-card rounded-md">
        <p className="text-center text-xl font-bold mb-4">Misi Perusahaan</p>
        <p>
          • Mengembangkan solusi teknologi wireless mutakhir yang memungkinkan
          pemantauan parameter lingkungan secara real-time dan jarak jauh
        </p>
        <p>• Meningkatkan kesadaran tentang pentingnya pemantauan lingkungan</p>
        <p>
          • Fokus pada inovasi dan peningkatan berkelanjutan untuk tetap menjadi
          yang terdepan dalam teknologi nirkabel dan penerapannya dalam
          pemantauan lingkungan.
        </p>
      </div>
    </div>
  );
}

export default VisionMision;
