import React from "react";

function CardServices({ image, title, description }) {
  return (
    <div className="rounded-md h-full">
      <div className=" h-[164px] relative rounded-md">
        <img
          src={image}
          alt=""
          className="h-full w-full object-cover rounded-md"
        />
      </div>
      <p className="text-center font-bold my-4">{title}</p>
      <p>{description}</p>
    </div>
  );
}

export default CardServices;
