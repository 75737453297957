import React from "react";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();

  return (
    <div className="sm:h-20 bg-white flex flex-col sm:flex-row justify-between px-11 items-center text-[#1172B8] shadow-navbar">
      <Link to={"/"}>
        <div className="w-32 py-2 sm:py-0">
          <img src="/assets/logo-bbt.png" alt="" />
        </div>
      </Link>
      <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-8 py-2 sm:py-0">
        <Link to={"/"}>
          <p className={location.pathname === "/" ? "font-bold" : ""}>
            Beranda
          </p>
        </Link>
        <Link to={"/our-services"}>
          <p
            className={location.pathname === "/our-services" ? "font-bold" : ""}
          >
            Layanan
          </p>
        </Link>
        <Link to={"/about-us"}>
          <p className={location.pathname === "/about-us" ? "font-bold" : ""}>
            Tentang Kami
          </p>
        </Link>
        <Link to={"/vision-mision"}>
          <p
            className={
              location.pathname === "/vision-mision" ? "font-bold" : ""
            }
          >
            Visi & Misi
          </p>
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
